import { camelCase, snakeCase, isUndefined, isPlainObject, isArray, set } from 'lodash';
// import deepMapKeys from 'deep-map-keys'
import { iterate } from 'utils';
import {
  get as rawGet,
  post as rawPost,
  patch as rawPatch,
  del as rawDel,
  put as rawPut,
  RequestOptions,
} from './raw';
import { API } from 'consts';

export interface ProxyResponse<T> {
  ok: boolean;
  response: any;
  data: T;
  errors: {
    fields: {};
    nonFields: any[];
  };
}

interface ApiOptions extends RequestOptions {
  f2bDict?: Record<string, string>;
}

function getF2BKey(key, f2BDict) {
  const keyInDictionary = f2BDict[key];
  if (!isUndefined(keyInDictionary)) {
    return keyInDictionary;
  }
  return snakeCase(key);
}

function getB2FKey(key, f2BDict) {
  const keyInDictionary = Object.keys(f2BDict).find((item) => f2BDict[item] === key);
  if (!isUndefined(keyInDictionary)) {
    return keyInDictionary;
  }
  return camelCase(key);
}

export function getF2BObject(obj, f2BDict = {}) {
  // console.log('getF2BObject original object:', obj)

  let translated;
  if (isPlainObject(obj)) {
    translated = {};
  }
  if (isArray(obj)) {
    translated = [];
  }

  iterate(obj, {
    onItem: (entry) => {
      // console.log('---')
      // console.log('Item path:', entry.path)
      if (entry.parentType === 'object') {
        // console.log('Item key:', entry.key)
        // console.log('Item value:', entry.value)

        if (!(isPlainObject(entry.value) || isArray(entry.value))) {
          if (!isUndefined(entry.value)) {
            set(
              translated,
              [...entry.path, entry.key].map((item) => getF2BKey(item, f2BDict)),
              entry.value,
            );
          }
        }
      }
      if (entry.parentType === 'array') {
        // console.log('Entry item:', entry.item)

        if (!(isPlainObject(entry.item) || isArray(entry.item))) {
          set(
            translated,
            [...entry.path, entry.idx].map((item) => getF2BKey(item, f2BDict)),
            entry.item,
          );
        }
      }
    },

    onDive: (entry, { path }) => {
      if (isArray(entry) && !entry.length) {
        set(
          translated,
          [...path].map((item) => getF2BKey(item, f2BDict)),
          [],
        );
      }

      if (isPlainObject(entry) && !Object.keys(entry).length) {
        set(
          translated,
          [...path].map((item) => getF2BKey(item, f2BDict)),
          {},
        );
      }
    },
  });

  // console.log('getF2BObject result:', translated)

  return translated;
}

function getB2FObject(obj, f2BDict = {}) {
  // console.log('getB2FObject original object:', obj)

  let translated;
  if (isPlainObject(obj)) {
    translated = {};
  }
  if (isArray(obj)) {
    translated = [];
  }

  iterate(obj, {
    onItem: (entry) => {
      // console.log('---')
      // console.log('Item path:', entry.path)
      if (entry.parentType === 'object') {
        // console.log('Item key:', entry.key)
        // console.log('Item value:', entry.value)

        if (!(isPlainObject(entry.value) || isArray(entry.value))) {
          set(
            translated,
            [...entry.path, entry.key].map((item) => getB2FKey(item, f2BDict)),
            entry.value,
          );
        }
      }
      if (entry.parentType === 'array') {
        // console.log('Entry item:', entry.item)

        if (!(isPlainObject(entry.item) || isArray(entry.item))) {
          set(
            translated,
            [...entry.path, entry.idx].map((item) => getB2FKey(item, f2BDict)),
            entry.item,
          );
        }
      }
    },

    onDive: (entry, { path }) => {
      if (isArray(entry) && !entry.length) {
        set(
          translated,
          [...path].map((item) => getF2BKey(item, f2BDict)),
          [],
        );
      }

      if (isPlainObject(entry) && !Object.keys(entry).length) {
        set(
          translated,
          [...path].map((item) => getF2BKey(item, f2BDict)),
          {},
        );
      }
    },
  });

  // console.log('getB2FObject result:', translated)

  return translated;
}

export async function processRequest<T>(request, f2bDict = {}): Promise<ProxyResponse<T>> {
  const result = {
    ok: false,
    response: null,
    data: null,
    errors: {
      fields: {},
      nonFields: [],
    },
  };

  try {
    const response = await request();
    result.response = response;

    let data = {} as any;
    if (response.status !== 204) {
      // No payload from the server on 204
      const text = await response.text();
      try {
        data = JSON.parse(text);
      } catch (err) {
        data = text;
      }
    }

    result.data = data;
    if (!response.ok) {
      switch (response.status) {
        case 400:
        case 409:
          // Server side validation failure

          if (typeof data === 'string') {
            result.errors.nonFields = [data];
            throw new Error('The server responded with an error!');
          }
          if (isPlainObject(data)) {
            if (data.non_field_errors) {
              result.errors.nonFields = data.non_field_errors;
              throw new Error('The server responded with errors!');
            }
            result.errors.fields = { ...getB2FObject(data, f2bDict) };
            throw new Error('The server responded with errors!');
          }
          if (isArray(data)) {
            if (data[0].non_field_errors) {
              result.errors.nonFields = data[0].non_field_errors;
              throw new Error('The server responded with errors!');
            }
            result.errors.fields = { ...getB2FObject(data, f2bDict) };
            throw new Error('The server responded with errors!');
          }

          break;

        case 401:
          // Auth invalid
          if (
            [`${API.root}services/owner/login/`, `${API.root}services/guest/login/`].includes(
              response.url,
            )
          ) {
            if (typeof data === 'string') {
              result.errors.nonFields = [data];
              throw new Error('The server responded with an error!');
            }
            if (isPlainObject(data)) {
              if (data.non_field_errors) {
                result.errors.nonFields = data.non_field_errors;
                throw new Error('The server responded with errors!');
              }
              result.errors.fields = { ...getB2FObject(data, f2bDict) };
              throw new Error('The server responded with errors!');
            }
            break;
          }

          result.errors.nonFields.push('Your session has expired!');
          // redirect to home screen
          if (response.url !== `${API.root}services/admin/masquerade/login/`) {
            // dont redirect on masquerade fail, since we're already on main page
            window.location.href = '/';
          }
          throw new Error('The API key has expired!');

        case 403:
          if (
            response.url.match(new RegExp(`${API.root}services/owner/\\d+/detail`)) ||
            response.url.match(new RegExp(`${API.root}services/guest/\\d+/detail`)) ||
            response.url === `${API.root}services/order/add/`
          ) {
            // On these urls, this is actually expected
            return result;
          }
          // logged into a different account
          result.errors.nonFields.push('Your session has expired!');
          // redirect to home screen
          if (response.url !== `${API.root}services/admin/masquerade/login/`) {
            // dont redirect on masquerade fail, since we're already on main page
            window.location.href = '/';
          }
          throw new Error('The API key has expired!');
        default:
          // Non-form-validation errors

          if (data.detail) {
            result.errors.nonFields.push(data.detail);
            throw new Error(data.detail);
          }
          throw new Error(data);
      }
    } else {
      // Successful response
      result.ok = true;
      result.data = getB2FObject(data, f2bDict);

      return {
        ...result,
      };
    }
  } catch (err) {
    return {
      ...result,
      ok: false,
      errors: {
        ...(Object.keys(result.errors.fields).length || result.errors.nonFields.length
          ? { ...result.errors }
          : { fields: {}, nonFields: ['Something went wrong! Please try again later'] }),
      },
    };
  }
}

// The requests which return an object containing the result status,
// data, structured errors and the request object
export function get<T>(path, payload = {}, options: ApiOptions = {}) {
  const { f2bDict } = options;
  const translatedPayload = getF2BObject(payload, f2bDict);

  return processRequest<T>(async () => rawGet(path, translatedPayload, options), f2bDict);
}

export function post<T>(path, payload = {}, options: ApiOptions = {}) {
  const { f2bDict } = options;
  const translatedPayload = getF2BObject(payload, f2bDict);

  return processRequest<T>(async () => rawPost(path, translatedPayload, options), f2bDict);
}

export function patch<T>(path, payload = {}, options: ApiOptions = {}) {
  const { f2bDict } = options;
  const translatedPayload = getF2BObject(payload, f2bDict);

  return processRequest<T>(async () => rawPatch(path, translatedPayload, options), f2bDict);
}

export function put<T>(path, payload = {}, options: ApiOptions = {}) {
  const { f2bDict } = options;
  const translatedPayload = getF2BObject(payload, f2bDict);

  return processRequest<T>(async () => rawPut(path, translatedPayload, options), f2bDict);
}

export function del<T>(path, options: ApiOptions = {}) {
  const { f2bDict } = options;

  return processRequest<T>(async () => rawDel(path, options), f2bDict);
}
