import { post, patch, get, put } from 'api';
import { post as rawPost } from './raw';
import useSWR from 'swr';

const DEFAULT_STORE_DESCRIPTION =
  'Welcome to our store! Purchase all items before use. Details and item location will be on your email receipt. Enjoy :-)';
export function updateStore(storeId, params) {
  const url = `/services/store/${storeId}/`;
  return patch(url, params, {
    f2bDict: {
      street1: 'street1',
      street2: 'street2',
    },
  });
}

const getStoreSales = ({ storeId, status = 'COMP' }) =>
  post('/services/order/search/', {
    storeId,
    status,
  });

export const getStoreDetailsURL = (storeId) => `/services/store/${storeId}/`;

const getStoreDetails = (storeId) => get(getStoreDetailsURL(storeId));

export const useStoreDetails = (storeId, enabled = true) => {
  const key = getStoreDetailsURL(storeId);
  const { data, error } = useSWR(enabled ? key : undefined, () => getStoreDetails(storeId));
  return {
    data: data?.data,
    isLoading: !error && !data,
    error,
  };
};

export const useStoreSales = (storeId) => {
  const key = `/services/order/search/${storeId}`;
  const { data, error } = useSWR(key, () => getStoreSales({ storeId }));
  return {
    data: data?.data,
    isLoading: !error && !data,
    error,
  };
};

export async function createStore(values) {
  const result = await post(
    '/services/store/',
    {
      ...values,
      isActive: true,
    },
    {
      f2bDict: {
        street1: 'street1',
        street2: 'street2',
      },
    },
  );
  return result;
}

export const duplicateStore = async ({ storeId, ...params }) => {
  const result = await post(`/services/store/copy/${storeId}/`, params);
  return result;
};

export async function createStores({ owner, stores = [] }) {
  const payload = stores.map((store) => ({
    owner,
    isActive: true,
    description: DEFAULT_STORE_DESCRIPTION,
    ...store,
  }));
  return post('/services/store/', payload, {
    f2bDict: {
      street1: 'street1',
      street2: 'street2',
    },
  });
}

export function exportOrderSales(storeId) {
  return rawPost(`/services/store/${storeId}/export/order/`);
}

export function exportProductSales(storeId) {
  return rawPost(`/services/store/${storeId}/export/orderproduct/`);
}

export function updateStores({ stores = [] }) {
  const payload = stores.map((store) => ({
    id: store.id,
    payoutMethod: store.payoutMethod,
  }));
  return put('/services/store/bulk-update/', payload);
}

export function storeSetLocalProducts(storeId, payload) {
  const url = `/services/store/${storeId}/set-local-products/`;
  return post(url, payload);
}

export const storeReorderProducts = ({ storeId, productsPositions }) => {
  const url = `/services/store/${storeId}/reorder-products/`;
  return post(url, { positions: productsPositions });
};
